export default {
  beforeRender: function() {
    this.style.padding = `calc(var(--mpadding)/4) calc(var(--mpadding)/1.5)`;
    this.style.borderRadius = `2pc`;
    this.style.backgroundColor = `var(--tag-background)`;
    this.style.color = `var(--tag-color)`;
    this.style.display = `inline-block`;
  },
  render: function() {
    if (this.attrs["label"]) {
      this.innerHTML = this.attrs["label"];
    }
  },
};
